import React from 'react';
import { Routes, Route, Link } from "react-router-dom";
import './App.css';
import TopHeader from './TopHeader';
import MenuSection from './MenuSection';
import TruckFactoring from "./TruckFactoring";
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Footer from './Footer';

function App() {
  return (  
  <>
  <TopHeader/>
  <MenuSection/>
  <Routes>
  <Route exact path="/" element={<Home />} /> 
  <Route exact path="/about" element={<About />} />
  <Route exact path="/truckfactoring" element={<TruckFactoring />} /> 
  <Route exact path="/contact" element={<Contact />} />
  </Routes>
  <Footer/>
  </>

   );
}

export default App;
