import React from 'react';
import emailjs from 'emailjs-com';
import './ContactStyle.css';
function sendEmail(e) {
  e.preventDefault();
  emailjs.sendForm('service_tul2s8h', 'template_ccowu4k',e.target,'3TbHzcIKa09cFWtsQ')
  .then((result) => {
    console.log(result.text);
}, (error) => {
    console.log(error.text);
});
};
const Contact = () => {
  return (
   <>
  <div className='container-fluid ab-backgound'>
    <div className='About-banner container'>
      <div className='row abt-banner-row'>
        <div className='col-md-6 col-sm-6 col-lg-6 col-xl-6 col-12 left-abt-bnner'>
        <h3 className='abt-bnner-txt'> Whenever you have a problem or query, feel free to contact us. We're always happy to help.  </h3>
        <p className="abt-bnner-text">At J&J, there is no one-size-fits-all policy. Non-recourse alternatives, 24/7 factoring, online account management, back-office help, a free mobile app, free fuel cards, and more.  </p>
        </div>
      </div>
    </div>
  </div>
  <div className='Contact-form-con container'>
    <div className='row contact-form-row'>
      <div className='col-12 col-md-5 col-lg-5 col-xl-5 col-sm-5'>
        <h3 className='Headings Contact-headings'>What Will Be Next Step?</h3>
        <p className='paragraph-text contact-para'>
          You are one step closer to build your perfect product
        </p>
        <h3 className="Footer-heading contact-heading">Contact us</h3>
        <p className='footer-address contact-address'>1030 Gettysburg Avenue # 104 Clovis CA 93612</p>   
        <p className='email-footer email-contact'>info@amptrucking.com</p>
        <p className='phone-footer contact-phone'>Phone Number – (559) 276-2240</p>
      </div>
      <div className='col-12 col-md-7 col-lg-7 col-xl-7 col-sm-7 form-con'>
        <b className='right-para paragarph-text '> Write us a few words about your project and we'll prepare a proposal for you with in 24 hours.</b>
        <form className='contact-form' onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Name'/>
          <input type="number" name='phone' placeholder='Your Phone'/>
          <input type="email" name='email' placeholder='Your Email'/>
          <input type="text" name='address' placeholder='Your Address'/>
          <textarea name='queries' placeholder='Your Queries' ></textarea>
          <input type="submit" name="sub-bttn" className='abt-bnr-bttn buttn'/>
        </form>
      </div>
    </div>
  </div>

  {/* End of banner */}
   </>
  )
}

export default Contact