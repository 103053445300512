import React from "react";
import './BannerStyle.css';
function Banner(){
    return(
    <>
    <div className="container banner-container">
        <div className="row banner-row">
            <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 Banner-head">
                <div className="banner-section">
                    <h3 className="Banner-Heading"> Receive Instant Access To Unlimited Money</h3>
                    <p className="text-banner"> There will be no more waiting thirty, sixty, or ninety days for payment; say bye-bye to payment delays with J&J factoring services. </p>
                </div>
            </div>
            <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                <img src="images/banner.png" className="main-banner"/>
            </div>
        </div>
    </div>
     
    </>
    )
}
export default Banner;