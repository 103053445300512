import React, { useState } from 'react';
import './TestimonialStyle.css';
const Testimonials = () => {
    const TContent = {
        0: {
            heading:"The Result Given are very satisfying",
            review: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum has been the industry's standard ",
            name: "Reily"
        },
        1: {
            heading:"The Result Given are very satisfying",
            review: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum has been the industry's standard ",
            name: "Thomas"
        },
        2: {
            heading:"The Result Given are very satisfying",
            review: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum has been the industry's standard ",
            name: "Shaun"
        },
        3: {
            heading:"The Result Given are very satisfying",
            review: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum has been the industry's standard ",
            name: "Ratts"
        }
        
    }
  

    const [current, setcurrent] = useState(TContent[0])
    const [active, setactive] = useState(0)

    function handlesetclick(event){
        setcurrent(TContent[event.target.getAttribute("data-quote")])
    }

  return (
  <>
    <div className='Testimonial-Container container'>
        <div className='row testimonail-row'>
            <div className='col-md-12 col-sm-12 col-lg-12 col-xl-12 testimonial-section'>
                <svg className='left-quote-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M96 224C84.72 224 74.05 226.3 64 229.9V224c0-35.3 28.7-64 64-64c17.67 0 32-14.33 32-32S145.7 96 128 96C57.42 96 0 153.4 0 224v96c0 53.02 42.98 96 96 96s96-42.98 96-96S149 224 96 224zM352 224c-11.28 0-21.95 2.305-32 5.879V224c0-35.3 28.7-64 64-64c17.67 0 32-14.33 32-32s-14.33-32-32-32c-70.58 0-128 57.42-128 128v96c0 53.02 42.98 96 96 96s96-42.98 96-96S405 224 352 224z"/>
                </svg>
                <p className='testimonial-heading'>{current.heading}</p>
                <p className='reviews'>{current.review}</p>
                <p className='Name'>{current.name}</p> 
                <div>
                    {Object.keys(TContent).map(index => (
                        <span className='next-dots' 
                        onClick={event => handlesetclick(event)}
                        data-quote={index}
                        key={index}
                        />
                    ))}
                </div>
            </div>
        </div>
    </div>
    

    </>
  )
}

export default Testimonials;