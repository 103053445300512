import React from 'react';
import './SubcsribeStyle.css';
const Subscribe = () => {
  return (
    <div className='container sub-container'>
        <div className="row sub-row">
            <div className='subscription-section col-md-12 col-sm-12 col-lg-12 col-xl-12'>
                <h3 className='Headings sub-heading'> Subscribe To Get The Latest <br/> News About Us</h3>
                <p className='small-text'> Enter below to get information about our <br/> new offerings and products directly into your mail.  </p>
             <form className='Subfrm'>
             <input type="text" className='Name-field' name="fname" placeholder="Enter Your Email "/>
             <input type="submit" className='Submit-button' name="submit" value="Submit"/>
             </form>
            </div>
        </div>
    </div>
  )
}

export default Subscribe;