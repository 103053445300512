import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import './MenuStyle.css';
function openNav() {
    document.getElementById('mySidepanel').style.cssText = `width:250px; display:block !important; transition: ease-in-out 2s; box-shadow:-6px 13px 50px -1px #000`;
  }
  
  function closeNav(){
    document.getElementById('mySidepanel').style.cssText = `width:0px; display:none; transition: ease-in-out 2s;`;
  }
function MenuSection(){
    return(
        <>
        <div className="container-fluid logo-section">
            <div className="container menu_logo_container">
                <div className="row menu-logo-row">
                    <button className="barrs" onClick={openNav}>&#9776;</button>
                    <div className="Logo-section col-md-3 col-sm-3 col-lg-3 col-12 col-xl-3">
                        <img className="Logo" src="images/logo.png" alt="Logo" />
                    </div>
                    <div className="Main-Menu col-md-9 col-sm-9 col-lg-9 col-12 col-xl-9" >
                    <nav id="menu" >
                        <div id="mySidepanel" className="sidepanel navbarOpen ">
                        <button className="closebtn" onClick={closeNav}>×</button>
                        <Link to="/">Home</Link>
                        <Link to="/about">About</Link>
                        <Link to="/truckfactoring">Truck Factoring</Link>
                        <Link to="/contact">Contact</Link>
                        </div>
                    </nav>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default MenuSection;