import React from 'react'
import { Routes, Route, Link } from "react-router-dom";
import './FooterStyle.css';
const Footer = () => {
  return (
    <div className='container-fluid footer-background'>
        <div className='container footer-container'>
            <div className='row footer-row'>
                <div className='col-md-12 col-sm-12 col-lg-12 col-xl-12'>
                <img src='./images/footer-image.png' className='footer-logo'/>
                </div>
                <div className='col-md-5 col-sm-5 col-lg-5 col-xl-5 col-12 container-footer-sec footer-section1'>
                    <p className='footer-text paragraph-text'> You have expenses to cover as a trucking firm. Customers typically pay 37 days after the invoice is submitted. Maintaining a consistent flow of cash is critical to the health of any company. It is our mission to give you the tools you need to be successful in the market, not only to provide carriers with money. Our unique transportation perks are designed to boost earnings and minimize costs for businesses around the country.</p>   
                </div>
                <div className='col-md-3 col-sm-3 col-lg-3 col-xl-3 col-12 container-footer-sec footer-section2'>
                    <h3 className="Footer-heading"> Links </h3>
                    <nav id="Footer-menu">
                        <Link to="/">Home</Link>
                        <Link to="/about">About</Link>
                        <Link to="/truckfactoring">Truck Factoring</Link>
                        <Link to="/contact">Contact</Link>
                    </nav>
                </div>
                <div className='col-md-4 col-sm-4 col-lg-4 col-xl-4 col-12 container-footer-sec footer-section3'>
                    <h3 className="Footer-heading">Contact us</h3>
                    <p className='footer-address'>1030 Gettysburg Avenue # 104 Clovis CA 93612</p>   
                    <p className='email-footer'>info@amptrucking.com</p>
                    <p className='phone-footer'>Phone Number – (559) 276-2240</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer;