import React from 'react';
import Banner from './Banner';
import './HomeStyle.css';
import Testimonials from './Testimonials';
import Subscribe from './Subscribe';
const Home = () => {
  return (
    <>
    <Banner/>
    <div className="container heading-benefits-section">
      <div className='row'>
        <div className='col-1 col-sm-1 col-lg-1 col-md-1 border-line-section'>
          <div className='border'></div>
        </div>
        <div className='col-11 col-sm-11 col-lg-11 col-md-11 Headings'>
          <p className='service-benefit Headings'>Benefits  You Get <br/> Using Our Services</p>
        </div>
      </div>
    </div>
    <div className='container benefits-content'>
      <div className='row'>
          <div className='benefits-sections col-12 col-sm-4 col-lg-4 col-md-4 row'>
            <div className='left-logo col-2 col-sm-2 col-lg-2 col-md-2'>
              <img src='./images/guaranteed.png' className='logo-benefit'  alt="Image"/>
            </div>
            <div className='right-text col-10 col-sm-10 col-lg-10 col-md-10'>
              <b>No Credits or Equity Required</b>
              <p>You will get the entire invoice amount once a modest deduction has been made.</p>
            </div>
          </div>
          <div className='benefits-sections col-12 col-sm-4 col-lg-4 col-md-4 row'>
            <div className='left-logo col-2 col-sm-2 col-lg-2 col-md-2'>
              <img src='./images/guaranteed.png' className='logo-benefit' alt="Image"/>
            </div>
            <div className='right-text col-10 col-sm-10 col-lg-10 col-md-10'>
              <b>No time-consuming paperwork involved</b>
              <p>Within minutes, you can finish setting up your account and submit your very first invoice.</p>
            </div>
          </div>
          <div className='benefits-sections col-12 col-sm-4 col-lg-4 col-md-4 row'>
            <div className='left-logo col-2 col-sm-2 col-lg-2 col-md-2'>
              <img src='./images/guaranteed.png' className='logo-benefit' alt="Image"/>
            </div>
            <div className='right-text col-10 col-sm-10 col-lg-10 col-md-10'>
              <b>No More Waiting Around for Customers Who Pay Late</b>
              <p>Get instant cash flow for your business. Stop waiting and start working </p>
            </div>
          </div>
      </div>
    </div>
    <div className='container divided-section-left'>
      <div className='divided-row row'>
        <div className='col-12 col-sm-6 col-lg-6 col-md-6 left-image-section'>
          <img src='./images/left-image.png' className='left-image' alt="Image"/>
        </div>
        <div className='col-12 col-sm-6 col-lg-6 col-md-6 Right-text-section'>
        <p className='right-heading Headings'>We will help not only to boost your business but also help minimise your expenses</p>
        <p className='paragraph-text'>You have expenses to cover as a trucking firm. Customers typically pay 37 days after the invoice is submitted. Maintaining a consistent flow of cash is critical to the health of any company. It is our mission to give you the tools you need to be successful in the market, not only to provide carriers with money. Our unique transportation perks are designed to boost earnings and minimize costs for businesses around the country.</p>
        </div>
      </div>
    </div>
    <div className='container divided-section-left'>
      <div className='divided-row row'>
        <div className='col-12 col-sm-6 col-lg-6 col-md-6 left-text-section'>
        <p className='right-heading Headings'>Find Cash Flow </p>
        <p className='paragraph-text'>Cash flow is king when it comes to factoring for trucking companies. A transportation company is only viable if it has enough money coming in to keep it afloat. Fuel, wages, maintenance, and more can all be taken care of using freight factoring. Your trucking company's cash flow can be easily managed by using this method. When we buy your freight invoices, we're advancing money on your accounts receivable. In order to continue transporting goods, freight factoring ensures that sufficient funds are available.</p>
        </div>
        <div className='col-12 col-sm-6 col-lg-6 col-md-6 right-image-section'>
          <img src='./images/right-image-home.png' className='left-image' alt="Image"/>
        </div>
      </div>
    </div>
    <div className='container divided-section-left'>
      <div className='divided-row row'>
        <div className='col-12 col-sm-6 col-lg-6 col-md-6 left-image-section'>
          <img src='./images/home-3img.png' className='left-image' alt="Image"/>
        </div>
        <div className='col-12 col-sm-6 col-lg-6 col-md-6 Right-text-section'>
        <p className='right-heading Headings'>We'll handle back-office while you can focus on running your business</p>
        <p className='paragraph-text'>Additionally, factoring companies provide back-office support. Managing communication, billing, and invoice collections can be difficult if your trucking firm is new to the field. The factoring company will take care of these tasks, freeing up your trucking company to focus on running your business. J&J funding can handle the back-office work so that your team can concentrate on delivering the load.</p>
        </div>
      </div>
    </div>
    <div className="container heading-benefits-section">
      <div className='row'>
        <div className='col-1 col-sm-1 col-lg-1 col-md-1 border-line-section'>
          <div className='border'></div>
        </div>
        <div className='col-11 col-sm-11 col-lg-11 col-md-11 Headings'>
          <p className='service-benefit Headings'>What Our Client Says</p>
        </div>
      </div>
    </div>
    <div className='Testimonials-section'>
      <Testimonials/>
    </div>
    <div className='Subscription'>
      <Subscribe/>
    </div>
    </>
  )
}

export default Home;
