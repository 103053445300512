import React from 'react'
import './TruckStyle.css';
import Subscribe from './Subscribe';
const TruckFactoring = () => {
  return (
    <>
    <div className='container banner-section-fact'>
      <div className='row banner-row-fact'>
        <div className='col-12 col-md-6 col-lg-6 col-xl-6 left-bnnr-section-fact'>
          <h3 className='Banner-Heading-fact Headings'>Get Access to Cashflow </h3>
          <p className='text-banner'>It is common knowledge that a steady flow of cash is essential to the success of any organization. Cash flow stability is necessary for successful enterprises, both for the day-to-day operations of the company and for the establishment of enough cash reserves to finance future expansion prospects. </p>
        </div>
        <div className='col-12 col-md-6 col-lg-6 col-xl-6 right-bnnr-section-fact'>
          <img className="Truck-banner-image" src="images/truck-bnr.png"/>
        </div>
      </div>
    </div>
    {/* End of Banner Section */}
    <div className="container heading-benefits-section">
      <div className='row'>
        <div className='col-1 col-sm-1 col-lg-1 col-md-1 border-line-section'>
          <div className='border'></div>
        </div>
        <div className='col-11 col-sm-11 col-lg-11 col-md-11 Headings'>
          <p className='service-benefit Headings'>Funding your firm via the selling of invoices to a <br/>Factor is one method invoice factoring works.</p>
        </div>
      </div>
    </div>
    <div className='container benefits-content benefits-content-trucking '>
      <div className='row'>
          <div className='benefits-sections col-12 col-sm-4 col-lg-4 col-md-4 row'>
            <div className='left-logo col-2 col-sm-2 col-lg-2 col-md-2'>
              <img src='./images/guaranteed.png' className='logo-benefit'  alt="Image"/>
            </div>
            <div className='right-text col-10 col-sm-10 col-lg-10 col-md-10'>
              <p>We get your invoice. </p>
            </div>
          </div>
          <div className='benefits-sections col-12 col-sm-4 col-lg-4 col-md-4 row'>
            <div className='left-logo col-2 col-sm-2 col-lg-2 col-md-2'>
              <img src='./images/guaranteed.png' className='logo-benefit' alt="Image"/>
            </div>
            <div className='right-text col-10 col-sm-10 col-lg-10 col-md-10'>
              <p>80-90% of your invoice is paid in cash. Accounts Receivable Department: We handle the invoices for you.</p>
            </div>
          </div>
          <div className='benefits-sections col-12 col-sm-4 col-lg-4 col-md-4 row'>
            <div className='left-logo col-2 col-sm-2 col-lg-2 col-md-2'>
              <img src='./images/guaranteed.png' className='logo-benefit' alt="Image"/>
            </div>
            <div className='right-text col-10 col-sm-10 col-lg-10 col-md-10'>
              <p>The customer pays us the full amount of the bill. We pay you the remaining 10% to 20% of your fees in cash.</p>
            </div>
          </div>
      </div>
    </div>
    {/* End of section2 */}
    <div className='Fact-Section2 container-fluid'>
      <div className='Fact-Section2-Container Section container'>
        <div className='row Fact-Section2-row'>
          <div className='Fact-Sub-Section col-md-3 col-sm-3 col-lg-3 col-12'>
            <img className="Truck-banner-image 3-img1" src="images/truck-img1.png"/>
          </div>
          <div className='Fact-Sub-Section col-md-3 col-sm-3 col-lg-3 col-12'>
            <img className="Truck-banner-image 3-img1" src="images/truck-img2.png"/>
          </div>
          <div className='Fact-Sub-Section col-md-6 col-sm-6 col-lg-6 col-12'>
            <p className='paragraph3 paragraph-text'>J&J Factoring is a provider of financial solutions for the transportation sector. Trucking companies all around the United States can count on us for quick, flexible operating capital to help them grow their businesses. Trucking factoring, scheduling, fuel cards, and safety, as well as coverage and carrier set-up, are some of the services we give to our trucking customers.</p>
          </div>
        </div>
      </div>
    </div>
    <div className='fact-section4 container'>
      <div className='row fact-row-section4'>
        <div className='col-12 col-md-4 col-lg-4 col-lg-4 col-xl-4'>
          <div className='section4 sub1'>
          <img src='./images/guaranteed.png' className='logo-dummy'  alt="Image"/>
            <b>Smooth Functioning</b>
            <p>Pay employees and take care of running costs. This will keep your staff motivated and work smooth</p>
          </div>
        </div>
        <div className='col-12 col-md-4 col-lg-4 col-lg-4 col-xl-4'>
          <div className='section4 sub1'>
          <img src='./images/guaranteed.png' className='logo-dummy'  alt="Image"/>
            <b>Increased inventory</b>
            <p>Take advantage of savings offered by the suppliers and invest in more inventory or equipment.</p>
          </div>
        </div>
        <div className='col-12 col-md-4 col-lg-4 col-lg-4 col-xl-4'>
          <div className='section4 sub1'>
          <img src='./images/guaranteed.png' className='logo-dummy'  alt="Image"/>
            <b>Boost Earnings</b>
            <p>Pay your taxes and boost your earnings. In turn boost your sales and business.</p>
          </div>
        </div>
      </div>
    </div>
    <div className='fact-Section3-back container-fluid'>
        <div className='container fact-Section3-container'>
        <div className='fact-section-row row'>
          <div className='fact-list col-12 col-sm-6 col-lg-6 col-md-6'>
            <b>The benefits of choosing our factoring services</b>
            <ul className='fact-list-ul'>
            <li> Factoring that is both recourses- and non-recourse-based</li>
            <li> A 2% flat rate</li>
            <li> Fees are not based on monthly volume limits </li>
            <li> No exit costs</li>
            <li> Credit checks are offered at no charge.</li>
            <li> No hidden charges</li>
            <li> Discounts for fuel cards</li>
            <li>  factor from anywhere with their free mobile app </li>
            <li> An account manager reporting to you</li>
            </ul>
          </div>
          <div className='fact-list col-12 col-sm-6 col-lg-6 col-md-6 right'>
            <b>How does our process work? </b>
            <ul className='fact-list-ul'>
            <li> Your truck driver completed the delivery of the goods as usual</li>
            <li>Your factoring company receives the rate confirmation, bill of lading (BOL), and any other relevant documentation that pertains to the cargo that you have hauled.</li>
            <li> The delivery is checked and double-checked by the factoring business with the brokerage. </li>
            <li> After the verification process is complete, the factoring business will use the financing method of your choice to make the payment deposit into your account.</li>
            <li> Depending on the method, you may obtain this money overnight or immediately</li>
            {/* <li>After you have been paid for the load, the factoring business will be in charge of collecting the payments for the remainder of the invoice's lifespan.</li>
            <li>After receiving payment from the client, the factoring business will take its fee and then close off the invoice.</li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className='Sub-fact container'>
      <div className='row'>
        <Subscribe/>
      </div>
    </div>
    </>
  )
}

export default TruckFactoring