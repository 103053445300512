import React from 'react';
import './AboutStyle.css';
import Subscribe from './Subscribe';
const About = () => {
  return (
    <>
  <div className='container-fluid ab-backgound'>
    <div className='About-banner container'>
      <div className='row abt-banner-row'>
        <div className='col-md-6 col-sm-6 col-lg-6 col-xl-6 col-12 left-abt-bnner'>
        <h3 className='abt-bnner-txt'>We Will Pay You Right Now For Your Work </h3>
        <p className="abt-bnner-text">J&J collaborates with shipping and transportation businesses of varying sizes to expedite payment for items that have been fulfilled. These payments provide your firm the cash it needs right now to fund its bills and invest in its future, which it can do thanks to these payments. </p>
        <button className='abt-bnr-bttn buttn'>Contact us</button>
        </div>
      </div>
    </div>
  </div>
  {/* End of banner */}

  <div className='abt-right-image container'>
    <div className='row'>
    <div className='col-md-6 col-sm-6 col-lg-6 col-xl-6 left-image-section Section row'>
        <img src="images/3-set.png" className='image-left abt-img-sec'/>
    </div>
      <div className='col-md-6 col-sm-6 col-lg-6 col-xl-6 right-text-section Section'>
        <div className='abt-text-sec'>
          <h3 className='Headings abt-heading'>About Us</h3>
          <p className='paragraph-text abt-para'>It is our ambition to improve the way small and medium-sized  get the finance they need to succeed. It is our belief that organizations must have the financial ﬂexibility necessary to respond rapidly to challenges and seize opportunities in real-time to prosper. To unlock the full potential of their assets, businesses today must rely on innovation that is led by expertise and a deep understanding of their business needs. This is where we believe we can help you.</p>
        </div>
      </div>
    </div>
  </div>
  {/* End Of Section 1 */}
  <div className='Team-leaders container'>
    <div className='row team-row'>
      {/* <div className='team-li'>
         <h3 className='Headings'> Meet Our Leaders</h3> 
        <p className='para team-para'>The devoted, motivated, and experienced executives who make up our forward-thinking leadership team are at the helm of our organization. When they collaborate with one another and with our staff, the skills, and experience that each member brings to the table help make a real difference for all of our stakeholders.,</p>
      </div> */}
      <div className='col-md-4 col-sm-6 col-lg-4 col-6 team-members'>
        <div className='member'>
            <p className='member-name'>Our Vision</p>
            <p className='member-para'>We envision that no business shall stop due to inconsistent cash flow. We will provide you with instant cash flow for smooth functioning of your business </p>
        </div>
      </div>
      <div className='col-md-4 col-sm-6 col-lg-4 col-6 team-members'>
        <div className='member'>
        <p className='member-name'>Our Mission</p>
        <p className='member-para'>We envision that no business shall stop due to inconsistent cash flow. We will provide you with instant cash flow for smooth functioning of your business </p>
        </div>
      </div>
      <div className='col-md-4 col-sm-6 col-lg-4 col-6 team-members'>
        <div className='member'>
        <p className='member-name'>Join Hands Today With J&J Truck Factoring</p>
        <p className='member-para'>Therefore we invite all the businesses to come and join hands with us to help us to help you manage your accounts and boost your earnings </p>
        </div>
      </div>
    </div>
  </div>
  {/* End of Team Section  */}
  <div className='Grey-Background-Section container-fluid'>
    <div className='abt-left-image container'>
      <div className='row'>
      <div className='col-md-6 col-sm-6 col-lg-6 col-xl-6 right-text-section Section'>
          <div className='abt-text-sec'>
            <h3 className='Headings abt-heading'>We are Your Associates</h3>
            <p className='paragraph-text abt-para'>We believe in your dreams and we are eager to take your aspirations to the next level. Your trucking company's goals will be fully reviewed by J&J's knowledgeable sales team. They will walk you through the process of factoring with us and answer any questions you may have. They can then come up with their strategy.</p>
          </div>
        </div>
      <div className='col-md-6 col-sm-6 col-lg-6 col-xl-6 left-image-section Section row'>
          <img src="images/abt-associate.png" className='image-left abt-img-sec'/>
      </div>
      </div>
    </div>
    <div className='abt-right-image2 container'>
      <div className='row'>
      <div className='col-md-6 col-sm-6 col-lg-6 col-xl-6 left-image-section Section row'>
          <img src="images/abt-associate2.png" className='image-left abt-img-sec'/>
      </div>
      <div className='col-md-6 col-sm-6 col-lg-6 col-xl-6 right-text-section Section'>
          <div className='abt-text-sec-right'>
            <h3 className='Headings abt-heading'>Why Choose J&J Funding?</h3>
            <p className='paragraph-text abt-para'>At J&J, there is no one-size-fits-all policy. Non-recourse alternatives, 24/7 factoring, online account management, back-office help, a free mobile app, free fuel cards, and more. With your input, we can implement a program to keep your transportation business running efficiently. Our customer-centric approach makes us one of the most reliable truck factoring companies in the market.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className='About-Subscripion col-md-12 col-sm-12 col-lg-12 col-xl-12'>
    <Subscribe/>
  </div>
  </>
  )
}

export default About